import styled from 'styled-components/macro';
import React, { useMemo } from 'react';
import Link from 'next/link';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useWindowScroll } from 'react-use';
import { useSetRecoilState } from 'recoil';

import DefaultOrdersListItem from './DefaultOrdersListItem';
import ShippingOrdersListItem from './ShippingOrdersListItem';
import LoadingMore from '../LoadingMore';
import { lastWindowScrollPositionAtom } from '../atoms';

const StyledLink = styled.a``;

const SeparatorTitle = styled.div`
  :after,
  :before {
    display: block;
    content: '';
    border-top: 2px solid grey;
    flex: 1;
  }

  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px;
`;

const carriersMap = {
  Unspecified: 'Unspecified',
  route4me: 'Delivery Van',
  UPS: 'UPS',
  USPS: 'USPS',
  local: 'Local Pick up'
};

const ListItemWrapper = ({ order, urlPathname, total, title, type, index }) => {
  const { y } = useWindowScroll();
  const storeScrollPosition = useSetRecoilState(lastWindowScrollPositionAtom);

  const handleClick = React.useCallback(() => {
    if (urlPathname === 'picking') {
      storeScrollPosition(y);
    }
  }, [urlPathname, y, storeScrollPosition]);

  if (order?.disabled) {
    return (
      <div>
      {title && <SeparatorTitle>{title}</SeparatorTitle>}
        <span>
          {type === 'default' && (
            <DefaultOrdersListItem order={order} countTotal={total} index={index + 1} />
          )}
          {type === 'shipping' && <ShippingOrdersListItem order={order} />}
        </span>
    </div>
    )
  }

  return (
    <div onClick={handleClick}>
      {title && <SeparatorTitle>{title}</SeparatorTitle>}

      <Link href={`${urlPathname}/${order.id}`}>
        <StyledLink>
          {type === 'default' && (
            <DefaultOrdersListItem order={order} countTotal={total} index={index + 1} />
          )}
          {type === 'shipping' && <ShippingOrdersListItem order={order} />}
        </StyledLink>
      </Link>
    </div>
  );
};

/**
 * Orders List Component
 * @param {{className: string; orders?: []; urlPathname: string; type?: 'default' | 'shipping'}} Props
 * @returns
 */
const OrdersList = ({
  className,
  orders,
  urlPathname,
  type = 'default',
  loading,
  hasNextPage,
  loadMore,
  total = 0,
  sortKey = 'defaultCarrier'
}) => {
  const sloadMore = () => {
    if (!loading && hasNextPage) {
      loadMore();
    }
  };

  const [sentryRef] = useInfiniteScroll({
    onLoadMore: sloadMore,
    hasNextPage,
    loading
    // rootMargin: '0px 0px 400px 0px',
  });

  const ordersListWithSectionTitle = useMemo(
    () =>
      orders.reduce(
        (acc, order) => {
          const isNewSection = order[sortKey] !== acc.lastSection;
          const title = isNewSection ? carriersMap[order[sortKey]] : null;
          const currentSection = isNewSection
            ? order[sortKey]
            : acc.lastSection;

          return { list: [...acc.list, { order, title }], lastSection: currentSection };
        },
        { list: [], lastSection: null }
      ),
    [orders, sortKey]
  );

  return (
    <div className={className}>
      {ordersListWithSectionTitle?.list?.map((item, index) => (
        <ListItemWrapper
          total={total}
          key={item?.order?.id}
          urlPathname={urlPathname}
          type={type}
          index={index}
          {...item}
        />
      ))}
      {hasNextPage && (
        <div ref={sentryRef}>
          <LoadingMore />
        </div>
      )}
    </div>
  );
};

export default styled(OrdersList)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
`;
