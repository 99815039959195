import { useRouter } from 'next/router';
import React from 'react';
import { Tabs, TabList, Tab, TabPanel, resetIdCounter } from 'react-tabs';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { errorsCounterAtom, tabIndexAtom } from '../core/components/atoms';
import FilterContainer from 'components/filterContainer';
import OrdersPicking from 'core/containers/OrdersPickinig';
import OrdersPacking from 'core/containers/OrdersPacking';
import OrdersSyncing from 'core/containers/OrdersSyncing';
import OrdersShipped from 'core/containers/OrdersShipped';
import OrdersError from 'core/containers/OrdersError';
import DefaultLayout from 'core/layouts/DefaultLayout';
import WithPrivateRoute from 'components/withPrivateRoute';
import { useOrderUnlock } from 'core/components/useOrderLock';
import { useSelectedOrderData } from 'core/components/SelectedOrderData';

const Home = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const router = useRouter();
  const [filterModal, setFilterModal] = React.useState(false);
  useSelectedOrderData();
  useOrderUnlock();
  const storeIndexTab = useSetRecoilState(tabIndexAtom);

  React.useEffect(() => {
    const { tab } = router.query;

    switch (tab) {
      case 'packing':
        setTabIndex(1);
        break;
      case 'syncing':
        setTabIndex(2);
        break;
      case 'error':
        setTabIndex(3);
        break;
      case 'shipped':
        setTabIndex(4);
        break;
      case 'picking':
      default:
        setTabIndex(0);
    }
  }, [router.query]);

  React.useEffect(() => {
    storeIndexTab(tabIndex)
  }, [storeIndexTab, tabIndex]);

  React.useEffect(() => {
    if (filterModal) {
      document.getElementsByTagName('BODY')[0].classList.add('disable-scroll');
    } else {
      document.getElementsByTagName('BODY')[0].classList.remove('disable-scroll');
    }
  }, [filterModal]);

  React.useEffect(() => {
    resetIdCounter();
  }, []);

  return (
    <DefaultLayout noPadding header={{ title: 'Order Feed', overrideTitle: true }}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={index => setTabIndex(index)}
        className={{ disabled: true }}
      >
        <div className="overflow-scroll">
          <TabList className="tabs-control">
            <Tab className="tabs-control-btn" id="picking">
              Picking
            </Tab>
            <Tab className="tabs-control-btn" id="packing">
              Packing
            </Tab>
            <Tab className="tabs-control-btn" id="syncing">
              Syncing
            </Tab>
            <Tab className="tabs-control-btn" id="error">
              <ErrorTabTitle />
            </Tab>
            <Tab className="tabs-control-btn" id="shipped">
              Shipped
            </Tab>
          </TabList>
        </div>

        <FilterContainer setFilterModal={setFilterModal} filterModal={filterModal} tabIndex={tabIndex} />

        <TabPanel className="select-tabs-screen">
          <OrdersPicking />
        </TabPanel>
        <TabPanel className="select-tabs-screen">
          <OrdersPacking />
        </TabPanel>
        <TabPanel className="select-tabs-screen">
          <OrdersSyncing />
        </TabPanel>
        <TabPanel className="select-tabs-screen">
          <OrdersError />
        </TabPanel>
        <TabPanel className="select-tabs-screen">
          <OrdersShipped />
        </TabPanel>
      </Tabs>
    </DefaultLayout>
  );
};

export default WithPrivateRoute(Home);

function ErrorTabTitle() {
  const errors = useRecoilValue(errorsCounterAtom)

  if (errors) {
    return `Error (${errors})`
  }

  return "Error"
}
